@import 'https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700|Roboto+Slab:100,300,400,700&subset=latin-ext';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css';
@import './reset.scss';
@import './utils.scss';
@import './flex-grid.scss';

body {
  font-family: 'Raleway', sans-serif;
  background: #252525;
  color: #fff;
  font-size: 14px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  color: #fff;
}

section.home-page {
  height: calc(100vh - 200px);
  width: 100vw;
  position: relative;
  background-image: url("../images/gfx_photo.png");
  background-repeat: no-repeat;
  background-position: right top;
  
  .logo {
    display: block;
    margin-top: 24px;
  }
  h1 {
    font-size: 48px;
    line-height: 60px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: 200;
    b {
      font-weight: normal;
    }
    &::before {
      content: '/';
      position: absolute;
      font-family: 'Roboto Slab';
      font-weight: 100;
      margin-left: -30px;
      margin-top: -2px;
      color: #149285;
    }
  }
}

section.contact {
  padding: 80px 0;
  height: 200px;
  background: #149285;
  position: relative;
  text-align: left;
  *::before {
    color: #fff;
  }

  h3 {
    font-size: 24px;
    font-weight: 300;
    position: relative;
    line-height: 1.4;
    &::before {
      content: '/';
      position: absolute;
      margin-left: -35px;
      margin-top: -9px;
      color: #fff;
      font-size: 46px;
      line-height: 46px;
      font-weight: 200;
      font-family: 'Roboto Slab';
    }
  }

  ul {
    text-align: right;
    margin: 0;
    height: 41px;
    padding-top: 5px;
    li {
      list-style-type: none;
      display: inline-block;
      margin-left: 10px;
      i {
        font-size: 32px;
      }
    }
  }
  .copy {
    position: absolute;
    bottom: 7px;
    font-size: 14px;
    font-weight: 600;
    b {
      font-family: Raleway;
      font-weight: 600;
    }
  }
}